import { animated } from 'react-spring'

import styles from './Button.css'

export function ButtonLinkPrimary({ children, href, animation = undefined, layoutClassName = undefined, target = '_self' }) {
  return (
    <LinkBase
      className={cx(styles.componentLinkPrimary, layoutClassName)}
      {...{ children, href, animation, target }}
    />
  )
}

export function ButtonLinkSecondary({ children, href, animation = undefined, layoutClassName = undefined, target = '_self' }) {
  return (
    <LinkBase
      className={cx(styles.componentLinkSecondary, layoutClassName)}
      {...{ children, href, animation, target }}
    />
  )
}

export function ButtonPrimary({ children, onClick, animation = undefined, layoutClassName = undefined }) {
  return (
    <ButtonBase
      className={cx(styles.componentPrimary, layoutClassName)}
      {...{ children, onClick, animation }}
    />
  )
}

export function ButtonSecondary({ children, onClick, animation = undefined, layoutClassName = undefined }) {
  return (
    <ButtonBase
      className={cx(styles.componentSecondary, layoutClassName)}
      {...{ children, onClick, animation }}
    />
  )
}

function ButtonBase({ children, onClick, className }) {
  return (
    <button className={cx(styles.componentBase, className)} {...{ onClick }}>
      {children}
    </button>
  )
}

function LinkBase({ children, href = undefined, animation, className, target }) {
  const Base = animation ? animated.div : React.Fragment

  return (
    <Base {...(animation ? { style: { ...animation, display: 'content' } } : {})}>
      <a
        className={cx(styles.componentLinkBase, className)}
        rel='nofollow noreferrer'
        {...{ href, target }}
      >
        {children}
      </a>
    </Base>
  )
}
