import { useElementSize } from '@kaliber/use-element-size'
import { ListItemWithParagraphs } from '/components/buildingBlocks/ListItem'
import { TextPrimarySm } from '/components/buildingBlocks/Text'
import { ButtonLinkPrimary } from '/components/buildingBlocks/Button'
import { useMediaQuery } from '@kaliber/use-media-query'

import mediaStyles from '/cssGlobal/media.css'
import styleContext from '/cssGlobal/style-context.css'
import styles from './CardStack.css'

export function CardStack({ cards, layoutClassName }) {
  return (
    <div className={cx(styles.component, layoutClassName)}>
      {cards.map((card, i) => (
        <Card
          key={i}
          index={i}
          lastItem={i + 1 === cards.length}
          {...{ card }}
        />
      ))}
    </div>
  )
}

function Card({ card, lastItem, index }) {
  const { title, buttonText, href, textLeft, textRight, list } = card ?? {}

  return (
    <Section {...{ lastItem, index }}>
      <Content {...{ title, buttonText, href, textLeft, textRight, index, list }} />
    </Section>
  )
}

function Content({ title, buttonText, textLeft, textRight, href, index, list }) {
  return (
    <div className={styles.componentContent}>
      {textLeft && (
        <ListItemWithParagraphs
          text={textLeft}
          heading={title}
          count={`0${index + 1}`}
          layoutClassName={styles.listItemLayout}
        />
      )}

      <div className={styles.contentRight}>
        {textRight && <TextPrimarySm children={textRight} />}
        {list && <List items={list} />}
      </div>
      <div className={styles.link}>
        <ButtonLinkPrimary layoutClassName={styles.buttonLayout} {...{ href }}>
          {buttonText && buttonText}
        </ButtonLinkPrimary>
      </div>
    </div>
  )
}

function Section({ index, lastItem, children }) {
  const { ref: sizeRef, size: { height } } = useElementSize()
  const isViewportLg = useMediaQuery(mediaStyles.viewportLg) ?? false

  const dataStyleContext = lastItem
    ? styleContext.contextDark
    : styleContext.contextLight

  return (
    <section
      ref={sizeRef}
      className={styles.componentSection}
      data-style-context={dataStyleContext}
      style={{ top: isViewportLg ? `calc((${-height}px + (${index + 1} * var(--offset))) + var(--card-height))` : undefined }}
    >
      <div className={styles.children}>{children}</div>
    </section>
  )
}

function List({ items }) {
  return (
    <ul className={styles.componentList}>
      {items.map((x, i) => (
        <li key={i} className={styles.listItem}>
          <TextPrimarySm>
            {x}
          </TextPrimarySm>
        </li>
      ))}
    </ul>
  )
}
