import { HeadingPrimaryLg } from '/components/buildingBlocks/Heading'
import { TextPrimarySm } from '/components/buildingBlocks/Text'
import { animated } from 'react-spring'

import styles from './ListItem.css'

export function ListItem({
  text,
  count,
  heading,
  HeadingElement = HeadingPrimaryLg,
  animation = undefined,
  layoutClassName = undefined
}) {
  return (
    <ListItemBase {...{ count, heading, HeadingElement, animation, layoutClassName }}>
      <TextPrimarySm>{text}</TextPrimarySm>
    </ListItemBase>
  )
}

export function ListItemWithParagraphs({
  text,
  count,
  heading,
  HeadingElement = HeadingPrimaryLg,
  animation = undefined,
  layoutClassName = undefined
}) {
  return (
    <ListItemBase {...{ count, heading, HeadingElement, animation, layoutClassName }}>
      {text.map((x, i) => (
        <TextPrimarySm layoutClassName={styles.textLayout} key={i}>{x}</TextPrimarySm>
      ))}
    </ListItemBase>
  )
}

function ListItemBase({
  count,
  heading,
  children,
  HeadingElement = HeadingPrimaryLg,
  animation = undefined,
  layoutClassName = undefined
}) {
  const Base = animation ? animated.li : 'li'

  return (
    <Base data-count={count} className={cx(styles.componentBase, layoutClassName)} style={animation}>
      <HeadingElement h='strong' layoutClassName={styles.headingLayout} {...{ heading }} />
      {children}
    </Base>
  )
}
